var Event = require('./event');
var IFrameElement = require('./iframe-element');
var WindowElement = require('./window-element');
var http = require('./http');

function VCredit(options) {
    this._options = options;
    this._subscribers = [];
    this._session = {
        gatewayUrl: undefined
    };
    this._sessionPromise = null;
    this._elementByFlowId = {};

    if (!options.secureSessionUrl) {
        throw new Error('VCredit:  options.secureSessionUrl not set.');
    }

    if (!options.manualInit) {
        this.init()
    }

}

VCredit.prototype._fire = function (event) {
    this._subscribers.forEach(function (subscriber) {
        if (subscriber.matches(event)) {
            subscriber.listener(event)
        }
    })
};

VCredit.prototype.on = function (event, listener) {
    var isRegex = event instanceof RegExp

    this._subscribers.push({
        event: event,
        listener: listener,
        matches: function(candidate) {
            if (isRegex) {
                return event.test(candidate.type)
            }

            return candidate.type === event
        }
    });
};

VCredit.prototype.off = function (event, listener) {
    this._subscribers = this._subscribers.filter(function (subscriber) {
        if (event && subscriber.event !== event) {
            return true;
        }

        if (listener && subscriber.listener !== listener) {
            return true;
        }

        return false;
    });
};

VCredit.prototype.init = function () {
    var self = this;
    window.addEventListener('message', this._onMessage.bind(this));

    self._sessionPromise = generateSession(this._options, 3)
        .then(function (session) {
            self._session = session;
            self._fire(new VCredit.Event('ready'));

            return self._session;
        })
        .catch(function (error) {
            fireSessionError(self, error);
        });

};

VCredit.prototype.destroy = function () {
    this._subscribers = [];
    window.removeEventListener('message', this._onMessage);
    var flowIds = Object.keys(this._elementByFlowId);

    for (var i = 0; i < flowIds.length; i++) {
        this._elementByFlowId[flowIds[i]].destroy()
    }
};

VCredit.prototype.launch = function (flowId, flowOptions, elementOptions) {
    var self = this;
    flowOptions = flowOptions || {};
    elementOptions = elementOptions || {};

    var targetEl;

    if (flowId === 'paycalc') {
        targetEl = document.body;
        elementOptions = {
            resizeTo: 'fixed',
            height: 0,
            width: 0
        }

        var paycalcListener = function(event) {
            self.off("flow.end.paycalc", paycalcListener)
            event.element.destroy();
        };

        self.on("flow.end.paycalc", paycalcListener )
    } else {
        var targetSelector = elementOptions.target || this._options.target
        var autoSelect = false;

        if (!targetSelector) {
            targetSelector = '#vcredit';
            autoSelect = true;
        }

        if (targetSelector === "_blank") {
           targetEl = "_blank"
        } else {
            targetEl = document.querySelector(targetSelector);
        }

        if (!targetEl && autoSelect) {
            targetEl = "_blank"
        }

    }

    if (!targetEl) {
        throw new Error("VCredit.launchApplication: " + targetSelector + " is not a valid DOM element")
    }

    if (this._elementByFlowId[flowId]) {
        this._elementByFlowId[flowId].destroy()
    }

    var element;

    if (targetEl === '_blank') {
        element = new VCredit.WindowElement(this, {
            flowId: flowId,
            flowOptions: flowOptions,
        })
    } else {
        element = new VCredit.IFrameElement(this, {
            parent: targetEl,
            resizeTo: elementOptions.resizeTo,
            width: elementOptions.width,
            height: elementOptions.height,
            flowId: flowId,
            flowOptions: flowOptions,
            src: buildDataUrl('<div class="gateway-message">Loading Storefront...</div>')
        });
    }

    if (flowOptions.checkout) {
        var endListener = function(event) {
            self.off("checkout.transition", endListener)
            var checkoutFlowData = $.extend({}, flowOptions.checkout);
            checkoutFlowData.applicationId = event.data.applicationId;
            checkoutFlowData.skipVerification = !event.data.resume;
            checkoutFlowData.transitionFromApply = true;
            self.launch('checkout', checkoutFlowData, elementOptions)
            setTimeout(function() {
                event.element.destroy();
            }, 200)
        };

        self.on("checkout.transition", endListener )
    }

    this._sessionPromise.then(function (session) {
        if (session) {
            var url = self._session.gatewayUrl + '/gateway?flowId=' + encodeURIComponent(flowId)  + '&ts=' + Date.now();

            if (elementOptions.disableLoadingIndicator) {
                url += '&disableLoadingIndicator=true';
            }

            element._setSrc(url)
        } else {
            console.error("Failed to Load Storefront: No session");
        }
    });

    return this._elementByFlowId[element._options.flowId] = element;
};

VCredit.prototype._onMessage = function (message) {
    var self = this;
    var payload = message.data;

    if (typeof payload !== 'object') {
        return
    }

    if (payload.source !== 'vcredit') {
        return
    }

    // if session is expired, generate a new one and send it to all flows
    if (payload.type === 'session.expired') {
        generateSession(this._options, 3)
            .then(function (session) {
                Object.keys(self._elementByFlowId).forEach(function (flowId) {
                    var element = self._elementByFlowId[flowId];
                    postMessageToElement(element, 'session.response', { session: session });
                });
            })
            .catch(function (err) {
                postMessageToElement(element, 'session.error', err);
                fireSessionError(self, err);
            });
        return
    }

    var element = self._elementByFlowId[payload.flowId];

    if (payload.type === "gateway.ready") {
        postMessageToElement(element, 'flow.launch', {
            session: self._session,
            flowId: element._options.flowId,
            options: element._options.flowOptions
        });
    }

    this._fire(new VCredit.Event(payload.type, element, payload.data));
};

function generateSession (options, tries) {
    var requestBody = options.secureSessionBody;

    if (typeof requestBody == 'function') {
        requestBody = requestBody()
    }

    var headers = options.secureSessionHeaders;

    return http.postJson(options.secureSessionUrl, requestBody, headers, tries).then(function (response) {
        return response.data;
    });
}

function postMessageToElement(element, type, data) {
    if (!element) {
        return;
    }

    element._postMessage({
        type: type,
        source: 'vcredit-storefront',
        data: data
    }, "*")

}

function buildDataUrl (html) {
    html = '<html><head><style type="text/css">.gateway-message{text-align:center;margin-top:20px;}</style></head><body>' + html + '</body></html>';

    return 'data:text/html;charset=utf-8,' + escape(html);
}

function fireSessionError(vcredit, errorResponse) {
    vcredit._fire(createEventFromErrorResponse(
        'error',
        errorResponse,
        'session-service',
        'Unable to fetch session token from ' + vcredit._options.secureSessionUrl + ' - ' + errorResponse.message
        ));
}

function createEventFromErrorResponse(type, errorResponse, component, message) {
    return new VCredit.Event(type, null, {
        category: errorResponse.category,
        component: component,
        code: errorResponse.code,
        httpStatusCode: errorResponse.httpStatusCode,
        message: message || errorResponse.message
    });
}

VCredit.IFrameElement = IFrameElement;
VCredit.WindowElement = WindowElement;
VCredit.Event = Event;

module.exports = VCredit;
