function WindowElement (vcredit, options) {
    this._vcredit = vcredit;
    this._options = options || {};
    this._childWindow = null;

}

WindowElement.prototype._postMessage = function(message, origin) {
    if (!this._childWindow) {
        return;
    }

    this._childWindow.postMessage(message, origin)
}

WindowElement.prototype.destroy = function () {
    try {
        if (this._childWindow) {
            this._childWindow.close();
            this._childWindow = null;
        }
    } catch (e) {
        // ignore
    }
}

WindowElement.prototype._setSrc = function(src) {
    this._childWindow = window.open(src, "vcredit");
}

module.exports = WindowElement;