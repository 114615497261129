var Http = {};

Http.getJson = function (url, maxTries) {
    var doRequest = function () {
        return Http.request({
            url: url,
            method: 'GET'
        });
    };

    return doWithTries(doRequest, maxTries);
};

Http.postJson = function (url, json, headers, maxTries) {
    if (maxTries == null) {
        maxTries = 1;
    }

    var doRequest = function () {
        return Http.request({
            url: url,
            method: 'POST',
            data: json,
            headers: headers
        });
    };

    return doWithTries(doRequest, maxTries);
};

Http.request = function (options) {
    return new Promise (function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        var requestData = options.data;

        xhr.open(options.method, options.url);

        if (options.headers) {
            for (var k in options.headers) {
                if (options.headers.hasOwnProperty(k)) {
                    xhr.setRequestHeader(k, options.headers[k])
                }
            }
        }

        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.timeout = options.timeout || 10000;

        xhr.onreadystatechange = function () {
            if (!xhr || xhr.readyState !== 4) {
                return;
            }

            // will get handled by onerror
            if (xhr.status === 0) {
                return;
            }

            var response = {
                status: xhr.status,
                statusText: xhr.statusText,
                data: xhr.responseText,
                request: xhr
            };

            var error = null;

            if (response.data) {
                try {
                    response.data = JSON.parse(response.data);
                } catch (e) {
                    response.data = null;
                    error = buildError('Failed to parse response as JSON', 'general', xhr, response);
                }
            }

            if (!validateStatusSuccess(xhr.status)) {
                error = buildError('Request failed with status code ' + response.status, 'general', xhr, response);
            }

            if (error === null) {
                resolve(response);
            } else {
                reject(error);
            }
        };

        xhr.ontimeout = function () {
            var error = buildError('Request Timeout', 'timeout', xhr);
            reject(error);
        };

        xhr.onerror = function () {
            var error = buildError('Network Error', 'general', xhr);
            reject(error);
        };

        if (requestData === undefined) {
            requestData = null;
        } else {
            requestData = JSON.stringify(requestData);
        }

        xhr.send(requestData);
    });
};

function doWithTries (fn, tries) {
    return fn().catch(function (err) {
        if (tries > 1) {
            return doWithTries(fn, tries - 1);
        } else {
            throw err;
        }
    });
}

function buildError (message, code, request, response) {
    var responseData = response && response.data ? response.data : {};

    return {
        category: responseData.category || 'service',
        message: responseData.message || message,
        code: responseData.code || code,
        httpStatusCode: response.status,
        request: request,
        response: response
    }
}

function validateStatusSuccess (statusCode) {
    return statusCode === 200;
}

module.exports = Http;