function IFrameElement (vcredit, options) {
    this._vcredit = vcredit;
    this._options = options || {};
    this._subscribers = [];
    var resizeTo = options.resizeTo || vcredit._options.resizeTo || 'target';

    var frameEl = this.dom = document.createElement('iframe');

    frameEl.setAttribute("frameborder", "0");
    frameEl.setAttribute("src", options.src);
    frameEl.setAttribute("class", "vcredit-frame");

    var parentEl = this._options.parent;

    if (resizeTo === 'target') {

        var resizeNow = function () {
            var cs = getComputedStyle(parentEl);

            var paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
            var paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

            var borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
            var borderY = parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

            var elementWidth = parentEl.offsetWidth - paddingX - borderX;
            var elementHeight = parentEl.offsetHeight - paddingY - borderY;

            frameEl.style.height = elementHeight + 'px';
            frameEl.style.width = elementWidth + 'px';
        };

        var resizeTimeout;
        var resizeDelay = function () {
            clearTimeout(resizeTimeout);
            setTimeout(resizeNow, 200)
        };

        window.addEventListener('resize', resizeDelay);
        this._subscribers.push({
            target: window,
            event: 'resize',
            listener: resizeDelay
        });


        setTimeout(resizeNow);
    } else if (resizeTo === 'content') {
        var self = this;
        this._resizeContentListener = function(event) {
            if (event.element === self) {
                frameEl.style.height = event.data.height + 'px'
            }
        };

        frameEl.style.width = '100%';

        vcredit.on('element.resize', this._resizeContentListener);
    } else if (resizeTo === 'fixed') {
        frameEl.style.height = this._options.height + 'px';
        frameEl.style.width = this._options.width + 'px';
    }

    parentEl.appendChild(frameEl)
}

IFrameElement.prototype.destroy = function () {
    this._subscribers.forEach(function (subscriber) {
        subscriber.target.removeEventListener(subscriber.event, subscriber.listener)
    });

    this._subscribers = [];

    if (this._resizeContentListener) {
        this._vcredit.off('element.resize', this._resizeContentListener)
    }

    this._options.parent.removeChild(this.dom);

    if (this._vcredit && this._vcredit._elementByFlowId[this._options.flowId] === this) {
        delete this._vcredit._elementByFlowId[this._options.flowId]
    }
};

IFrameElement.prototype._postMessage = function(message, origin) {
    if (!this.dom || !this.dom.contentWindow) {
        return;
    }

    this.dom.contentWindow.postMessage(message, origin)
}

IFrameElement.prototype._setSrc = function (src) {
    this._options.src = src;
    this.dom.setAttribute("src", src);
};


module.exports = IFrameElement;