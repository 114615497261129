var VCredit = require('./lib/storefront');

var lib = {};
var vcredit;

lib.create = function (options) {
    options = options || {};
    if (vcredit) {
        vcredit.destroy()
    }

    return vcredit = new VCredit(options)
};

module.exports = lib;
